import React, { Component } from 'react';
//import { Button } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Table, TableCell, Modal, TableBody, Radio, TableHead, TableContainer, TableRow, Button, Box, Tooltip, Paper, Toolbar, Typography, IconButton, Grid, Badge } from '@material-ui/core';
import Loader from '../../../loader';
import { connect } from 'react-redux';
import axios from "axios";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import i18n from '../../../../i18next';
import customStyle from '../../../../Assets/CSS/stylesheet_UHS';
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import Cart from '../../../../Assets/Images/cart.svg'
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import CommonDropFamily from '../CommonPopup/CommonDropFamily';
import CommonDropDwn from '../CommonPopup/CommonDropDwn';
import CommonTable from './CommonTable'
import '../Add-ons/addOnsScreen.css';


const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: '#fa6446',
  },
  tooltip: {
    backgroundColor: '#fa6446',
    border: '1px solid #dadde9',
    fontSize: '12px'
  },
}));

const useStylesBootstrap_otherQuote = makeStyles(theme => ({
  arrow: {
    color: '#4a4b57',
  },
  tooltip: {
    backgroundColor: '#4a4b57',
    border: '1px solid #dadde9',
    fontSize: '12px'
  },
}));


const StyledTableRow = withStyles({
  root: {
    backgroundColor: "#F4F4F4",
    '&.Mui-selected': {
      backgroundColor: "#fdb315",
    },
    "&.Mui-selected.MuiTableRow-hover": {
      backgroundColor: "#fdb315",
    },
  },
})(TableRow);


const ProceedButton = withStyles(
  {
    root: {
      backgroundColor: '#0e234d',
      color: '#ffffff',
      fontWeight: "bold",
      '&:hover': {
        backgroundColor: '#420045',
        boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
      },
      '&:focus': {
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: '#BDBDBD',
        color: '#ffffff'
      },
    },
  }

)(Button);

const styles = theme => ({
  table: {
    minWidth: 400,
  },
});




class MultiAddons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientid: sessionStorage.getItem("CLIENT_ID"),
      subId: JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
      loaderShow: this.props.loaderShow,
      addonList: [],
      selected: null,
      planSelectedAmount: null,
      selecdPlanID: null,
      appFeeAmount: null,
      data: null,
      listOfCost: [],
      familyList: [],
      selectedFamily: null,
      step: this.props.indexOfMultiAddons,
      arrayOfAddonAmount: [],
      listofAddonSelected: [],
      arrayofAddonSelected: [],
      coreSelectedFamily: null,
      addonSelectedFamily: [],
      isDisabled: false
    };
  }

  componentDidMount() {
    this.setState({ isDisabled: this.props.isAddonDisabled })
    // axios.get(process.env.REACT_APP_BASE_URL+"/plan/getAddonList/"+`/${this.state.clientid}`).then((response)=>{
    //   this.setState({addonList: response.data.response}, () =>{
    this.getAddonData(this.props.addon);
    this.getCorePlanDetails();
    // })
    // })
  }


  getAddonData = (type) => {
    axios.get(process.env.REACT_APP_BASE_URL + "/addon/getAddonData" + `/${this.state.subId}/${this.state.clientid}/${type}`).then((res) => {
      this.setState({
        data: res.data.response,
        selectedFamily: res.data.response[0].categoryAmountList[0].category
      }, () => {

        let listCost = [];
        this.state.data.map((res) => {
          listCost.push(res.categoryAmountList[0].amount);
        });



        let familyList = [];
        this.state.data.map((res) => {
          familyList.push(res.categoryAmountList[0].category);
        })

        this.setState({ listOfCost: listCost, familyList: familyList }, () => {
          axios.get(`${process.env.REACT_APP_BASE_URL}/plan/getMemberPlan/${this.state.subId}/${type}`).then((resp) => {
            this.setState({ loaderShow: false })
            if (resp.data.response) {
              let index = this.state.data.indexOf(this.state.data.filter(x => x.planId === resp.data.response.planId)[0]);
              let listOfCost = this.state.listOfCost;
              let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
              let listofAddonSelected = this.state.listofAddonSelected;
              let arrayofAddonSelected = this.state.arrayofAddonSelected;
              let addonSelectedFamily = this.state.addonSelectedFamily;

              let flagRemoveDepentent = this.state.data[index].categoryAmountList.find(x => x.category === resp.data.response.category) ? true :false;

              let family = this.state.familyList;

              // family[index] = flagRemoveDepentent ? resp.data.response.category : this.state.data[index].categoryAmountList[0].category;
              arrayOfAddonAmount[this.state.step] = flagRemoveDepentent ? resp.data.response.amount :  this.state.data[index].categoryAmountList[0].amount;
              listofAddonSelected[this.state.step] = resp.data.response.planId;
              let selectedAddon = res.data.response.filter(item => item.planId == resp.data.response.planId);
              arrayofAddonSelected[this.state.step] = selectedAddon[0]
              // listOfCost[index] =  flagRemoveDepentent ? resp.data.response.amount:  this.state.data[index].categoryAmountList[0].amount;
              // addonSelectedFamily[this.state.step] = flagRemoveDepentent ? resp.data.response.category: this.state.data[index].categoryAmountList[0].category;
              this.props.setArrayOfAddonAmount(arrayOfAddonAmount[this.state.step]);
              this.props.setlistofAddonSelected(listofAddonSelected[this.state.step]);
              this.props.setarrayofAddonSelected(arrayofAddonSelected[this.state.step]);
              this.props.setaddonSelectedFamily(addonSelectedFamily[this.state.step])
              this.setState({
                // selectedFamily: this.state.isDisabled ? null : flagRemoveDepentent ? resp.data.response.category : this.state.data[index].categoryAmountList[0].category,
                arrayOfAddonAmount: arrayOfAddonAmount,
                listofAddonSelected: this.state.isDisabled ? null : listofAddonSelected,
                arrayofAddonSelected: this.state.isDisabled ? null : arrayofAddonSelected,
                selected: this.state.isDisabled ? null : index,
                listOfCost: listOfCost,
                familyList: family,

                addonSelectedFamily: this.state.isDisabled ? null : addonSelectedFamily
              })
            }
          })
        })

      })
    })
  }
  getCorePlanDetails = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/plan/getMemberPlan/${this.state.subId}/Core`).then((resp) => {
      if (resp.data.response)
        this.setState({
          planSelectedAmount: resp.data.response.amount,
          appFeeAmount: resp.data.response.appFee,
          selecdPlanID: resp.data.response.planId,
          coreSelectedFamily: resp.data.response.category
        })
      else
        this.props.backStep();
    })
  }
  handleClick = (index, seletedAddon) => {
    console.log("::::", index, seletedAddon, this.state.familyList, index)
    if (this.state.selected === index) {
      let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
      let listofAddonSelected = this.state.listofAddonSelected;
      let arrayofAddonSelected = this.state.arrayofAddonSelected;
      let addonSelectedFamily = this.state.addonSelectedFamily;
      addonSelectedFamily[this.state.step] = this.state.familyList[index];
      listofAddonSelected[this.state.step] = null;
      arrayofAddonSelected[this.state.step] = null;
      arrayOfAddonAmount[this.state.step] = 0;
      this.props.setArrayOfAddonAmount(arrayOfAddonAmount[this.state.step]);
      this.props.setlistofAddonSelected(listofAddonSelected[this.state.step]);
      this.props.setarrayofAddonSelected(arrayofAddonSelected[this.state.step]);
      this.props.setaddonSelectedFamily(addonSelectedFamily[this.state.step])
      this.setState({ selected: null, arrayOfAddonAmount: arrayOfAddonAmount, listofAddonSelected: listofAddonSelected, arrayofAddonSelected, addonSelectedFamily: addonSelectedFamily });
    } else {
      let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
      let costList = this.state.listOfCost;
      let listofAddonSelected = this.state.listofAddonSelected;
      let arrayofAddonSelected = this.state.arrayofAddonSelected;
      listofAddonSelected[this.state.step] = seletedAddon.planId;
      arrayofAddonSelected[this.state.step] = seletedAddon;
      let addonSelectedFamily = this.state.addonSelectedFamily;
      addonSelectedFamily[this.state.step] = this.state.familyList[index];
      arrayOfAddonAmount[this.state.step] = costList[index];
      this.props.setArrayOfAddonAmount(arrayOfAddonAmount[this.state.step]);
      this.props.setlistofAddonSelected(listofAddonSelected[this.state.step]);
      this.props.setarrayofAddonSelected(arrayofAddonSelected[this.state.step]);
      this.props.setaddonSelectedFamily(addonSelectedFamily[this.state.step])
      this.setState({ selected: index, arrayOfAddonAmount: arrayOfAddonAmount, listofAddonSelected: listofAddonSelected, arrayofAddonSelected, addonSelectedFamily: addonSelectedFamily });
    }
  };
  selectedClick = (index, seletedAddon) => {
    let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
    let costList = this.state.listOfCost;
    let addonSelectedFamily = this.state.addonSelectedFamily;
    addonSelectedFamily[this.state.step] = this.state.familyList[index];
    let listofAddonSelected = this.state.listofAddonSelected;
    let arrayofAddonSelected = this.state.arrayofAddonSelected;
    arrayofAddonSelected[this.state.step] = seletedAddon;
    arrayOfAddonAmount[this.state.step] = costList[index];
    this.props.setArrayOfAddonAmount(arrayOfAddonAmount[this.state.step]);
    this.props.setlistofAddonSelected(listofAddonSelected[this.state.step]);
    this.props.setarraytofAddonSelected(arrayofAddonSelected[this.state.step]);
    this.props.setaddonSelectedFamily(addonSelectedFamily[this.state.step])
    this.setState({ selected: index, arrayOfAddonAmount: arrayOfAddonAmount, listofAddonSelected: listofAddonSelected, arrayofAddonSelected, addonSelectedFamily: addonSelectedFamily });
  }
  submitAndNextAddon = () => {

    let obj = {
      subId: this.state.subId,
      planId: this.state.data[this.state.selected] ? this.state.data[this.state.selected].planId : null,
      planCode: this.state.data[this.state.selected] ? this.state.data[this.state.selected].planCode : null,
      planName: this.state.data[this.state.selected] ? this.state.data[this.state.selected].planName : null,
      amount: this.state.listOfCost[this.state.selected] ? this.state.listOfCost[this.state.selected] : 0,
      appFee: 0,
      category: this.state.familyList[this.state.selected] ? this.state.familyList[this.state.selected] : null,
      planType: this.state.addonList[this.state.step]
    }

    this.setState({ loaderShow: true })
    axios.post(process.env.REACT_APP_BASE_URL + "/plan/saveMemberPlan", obj).then((res) => {
      this.setState({ selected: null, step: this.state.step + 1, listOfCost: [], familyList: [] }, () => {
        this.getAddonData(this.state.addonList[this.state.step]);
      })
    })

  }
  SubmitPlanRequest = () => {
    let obj = {
      subId: this.state.subId,
      planId: this.state.data[this.state.selected] ? this.state.data[this.state.selected].planId : null,
      planCode: this.state.data[this.state.selected] ? this.state.data[this.state.selected].planCode : null,
      planName: this.state.data[this.state.selected] ? this.state.data[this.state.selected].planName : null,
      amount: this.state.listOfCost[this.state.selected] ? this.state.listOfCost[this.state.selected] : 0,
      appFee: 0,
      category: this.state.familyList[this.state.selected] ? this.state.familyList[this.state.selected] : null,
      planType: this.state.addonList[this.state.step]
    }
    this.setState({ loaderShow: true })



    this.setState({ loaderShow: true })
    axios.post(process.env.REACT_APP_BASE_URL + "/plan/saveMemberPlan", obj).then((res) => {
      this.setState({ selected: null, listOfCost: [], familyList: [] }, () => {
        this.props.onClick();
        this.setState({ loaderShow: false })
      })
    }).catch(err => {
      console.log(err);
      this.setState({ loaderShow: false })
    })

  }
  backScreen = () => {
    this.setState({ loaderShow: true })
    let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
    let listofAddonSelected = this.state.listofAddonSelected;
    let arrayofAddonSelected = this.state.arrayofAddonSelected;
    listofAddonSelected.pop();
    arrayofAddonSelected.pop();
    arrayOfAddonAmount.pop();
    this.setState({ selected: null, step: this.state.step - 1, arrayOfAddonAmount: arrayOfAddonAmount, listofAddonSelected: listofAddonSelected, arrayofAddonSelected, listOfCost: [], familyList: [] }, () => {
      this.getAddonData(this.state.addonList[this.state.step]);
    })
  }
  render() {
    var totalAmountOfAddon = 0;
    this.props.arrayOfAddonAmount.length > 0 && this.props.arrayOfAddonAmount.map(val => {
      totalAmountOfAddon += val ? val : 0;
    })
    if (this.state.data)
      return (
        <div style={{}}>
          {this.state.loaderShow ? <Loader /> : ''}




          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          // size={dense ? "small" : "medium"}
          >
            <TableBody>
              {this.state.data.map((row, index) => {
                const isItemSelected = index === this.state.selected;
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    hover
                    role="radio"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.planInfo}
                    selected={isItemSelected}

                  >
                    <TableCell padding="checkbox" style={{ paddingTop: 0 }} onClick={(event) => this.state.isDisabled ? event.stopPropagation() : this.handleClick(index, this.state.data[index])}>
                      <Radio
                        disabled={this.state.isDisabled}
                        checked={isItemSelected}
                        value={row.planInfo}
                        style={{
                          color: this.state.isDisabled ? "grey" : "#162242"
                        }}
                        name="radio-buttons"
                        className="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      style={{ paddingTop: 10, paddingBottom: 10, color: this.state.isDisabled && "grey" }}
                      onClick={(event) => this.state.isDisabled ? event.stopPropagation() : this.handleClick(index, this.state.data[index])}
                    >
                      Add <b style={{ color: this.state.isDisabled && "grey" }}>{row.planName}</b>
                    </TableCell>

                    <TableCell align="left" style={{ width: "10%", paddingTop: 10, paddingBottom: 10 }} onClick={(event) => this.state.isDisabled ? event.stopPropagation() : this.handleClick(index, this.state.data[index])}>
                    </TableCell>

                    <TableCell align="left" style={{ width: "10%", paddingTop: 10, paddingBottom: 10 }} onClick={(event) => this.state.isDisabled ? event.stopPropagation() : this.selectedClick(index, this.state.data[index])}>

                      {this.state.familyList[index] && <CommonDropFamily
                        disabled={this.state.isDisabled}
                        value={row.categoryAmountList}
                        index={index}
                        selectedIndex={this.state.selected === index}
                        selectedFamily={this.state.familyList[index]}
                        selectCost={(value, ind) => {
                          let list = this.state.listOfCost;
                          let familyList = this.state.familyList;
                          let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
                          let addonSelectedFamily = [];

                          let amount = row.categoryAmountList.filter(
                            (x) => x.category === value
                          )[0].amount;
                          familyList[ind] = value;
                          list[ind] = amount;
                          arrayOfAddonAmount[this.state.step] = amount;
                          addonSelectedFamily[this.state.step] = value;
                          this.props.setArrayOfAddonAmount(arrayOfAddonAmount[this.state.step]);
                          this.setState({ selectedFamily: value, listOfCost: list, familyList: familyList, arrayOfAddonAmount: arrayOfAddonAmount, addonSelectedFamily: addonSelectedFamily });
                        }}
                      />}
                    </TableCell>
                    <TableCell align="right" style={{ width: "10%", paddingTop: 10, paddingBottom: 10, fontFamily: "Lato", fontSize: "15px", color: "rgba(0, 0, 0, 0.6)" }}
                      onClick={(event) => this.state.isDisabled ? event.stopPropagation() : this.handleClick(index, this.state.data[index])}
                    >
                      <span
                        className="cost-per-month"
                        style={{
                          fontWeight: 700,
                          fontSize: "25px",
                          color: this.state.isDisabled ? "grey" : index === this.state.selected ? "#fff" : "#91b40f"
                        }}
                      >
                        ${this.state.listOfCost[index] ? Number(this.state.listOfCost[index]).toFixed(2) : 0}
                      </span>
                      /MONTH
                    </TableCell>
                    <TableCell align="right" style={{ width: "10%", paddingTop: 10, paddingBottom: 10 }}>

                      <IconButton onClick={(e) => this.state.isDisabled ? e.stopPropagation() : window.open(row.link)}>
                        <span style={{ fontSize: 13, fontWeight: "bold", color: this.state.isDisabled ? "grey" : isItemSelected ? "#0e234d" : "#009cde", width: 100 }}>VIEW DETAILS</span>
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )
    else return <> <Loader /> </>
  }
}

const mapStateToProps = state => {
  return {
    subId: state.subId
  };
}

export default withStyles(styles)(connect(mapStateToProps)(MultiAddons));






